class BuildVersionCtrl {
  constructor($scope, $http, env, LoaderFactory, $window, $timeout) {
    Object.defineProperties(this, {
      $scope: { value: $scope },
      $http: { value: $http },
      env: { value: env },
      $timeout: { value: $timeout },
      $window: { value: $window },
      LoaderFactory: { value: LoaderFactory }
    });
    $scope.version_build = '';

    LoaderFactory.show();

    Promise.resolve($http.get('./config.xml'))
      .then((response) => JSON.parse(xml2json(this.parseXml(response.data), '  ')))
      .then((data) => {
        if (data['widget@version']) {
          $scope.version_build = 'Version ' + data['widget@version'];
          if (env === 'development') {
            $scope.version_build += 'ß';
          }
        }
      })
      .catch((err) => console.error(err))
      .then(() => LoaderFactory.hide());
  }
  parseXml(xml) {
    var dom, e;
    dom = null;
    if (window.DOMParser) {
      try {
        dom = (new DOMParser()).parseFromString(xml, 'text/xml');
      } catch (error1) {
        e = error1;
        dom = null;
      }
    }
    return dom;
  }
}

app.component('buildVersion', {
  template: require('scripts/components/build-version/build-version.html'),
  controller: BuildVersionCtrl
});
