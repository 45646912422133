class DotNavigation {
    constructor ($scope, $rootScope, $element, ReducedService) {
        Object.defineProperties(this,{
            '$rootScope': { value: $rootScope }
          });
        $scope.dotNavigationEnabled = !ReducedService.dot_navigation;

        this.elm = $element[0].previousElementSibling;

        this.$rootScope.$on('update-header', function (e, data) {
            $scope.currentPageNumber = data.currentPageNum;
            $scope.pages = data.tolatPages;
          });
    }
    goToPage(pageNum){
        var pageWidth = window.innerWidth;
        var translateX = -(pageNum * pageWidth);
        for (let i=0; i< this.project.pages.length; i++) {
            if (i === pageNum) {
                this.elm.children[pageNum].style.transition = 'opacity 200ms ease-in-out';
                this.elm.children[pageNum].style.opacity = 1;
            } else {
                this.elm.children[i].style.transition = 'opacity 200ms ease-in-out';
                this.elm.children[i].style.opacity = 0;
            }
        }
        //this.elm.style.webkitTransform = 'translate(' + translateX + 'px, 0px)';
        this.elm.style.left = `${translateX}px`;
        this.elm.style.webkitTransitionDuration = "200ms";

        this.$rootScope.$broadcast('update-header', {
            pageTitle: this.project.pages[pageNum].title,
            title: this.project.name,
            tolatPages: this.project.pages.length,
            currentPageNum: pageNum,
            projectType: this.project.type
          });
    }
}

app.component('dotNavigation', {
    template: require('scripts/components/dot-navigation/dot-navigation.html'),
    controller: DotNavigation,
    bindings: {
        project: '='
    },
});
