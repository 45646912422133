class EBSList {
  $scope:            any;
  $timeout:          any;
  api:               any;
  authService:       any;
  assignmentFactory: any;
  dbFactory:         any;
  errFactory:        any;

  timeout:       any;
  reloadEbsList: any;

  activeEBS:             any[]   = [];
  archivedEBS:           any[]   = [];
  ebsList:               any[]   = [];

  mappedActiveEBS:       any[]   = [];
  mappedArchivedEBS:     any[]   = [];

  totalCount:            number  = 0;
  totalLoaded:           number  = 0;
  notConfirmedActiveEBS: number  = 0;

  firstLoad:             boolean = false;
  loaded:                boolean = false;
  loading:               boolean = false;

  isOpened:              boolean = false;
  isOpenedArchive:       boolean = false;

  height:                number  = 0;
  heightArchive:         number  = 0;

  readonly COLECTION_NAME: string = 'ebs';
  constructor($scope: any, $timeout: any, $rootScope: any, API: any, AuthService: any, AppFactory: any, AssignmentFactory: any, DBFactory: any, ErrFactory: any, LoaderFactory: any) {
    Object.defineProperties(this, {
      $scope:            { value: $scope            },
      $timeout:          { value: $timeout          },
      api:               { value: API               },
      authService:       { value: AuthService       },
      assignmentFactory: { value: AssignmentFactory },
      dbFactory:         { value: DBFactory         },
      errFactory:        { value: ErrFactory        }
    });

    if (!$rootScope.ebs) $rootScope.ebs = {};
    if (!$rootScope.ebs.loaded) $rootScope.ebs.loaded = false;
    this.firstLoad = !$rootScope.ebs.loaded;

    let call;
    if (this.firstLoad) LoaderFactory.show()
    if (AppFactory.isNetwork() && this.firstLoad) call = this.loadEbsFirstPage();
    else call = this.loadLocalEbs();

    Promise.resolve(call).then(() => {
      $rootScope.ebs.loaded = true;
      this.loaded = true;
      this.$scope.$apply();
    });

    this.loading = false;
    this.loaded  = false;

    this.reloadEbsList = $rootScope.$on('project.sync', (e) => {
      this.$timeout(() => { if (!this.loading) {
        this.close();
        this.loadEbsFirstPage(); 
      }});
    });
  }

  private loadEbsFirstPage(): Promise<any> {
    this.loading = true;
    return Promise.resolve(this.api.getAllEBS())
    .then(data => this.parceEbs(data));
  }

  ebsLoadAll(): Promise<any> {
    this.loading = true;
    return Promise.all([
      this.api.getAllEBS(1, 5),
      this.api.getAssignments(1, 5)
    ])
    .then(res => Promise.all([
      this.api.getAllEBS(1,      res[0].meta.paging.total_count),
      this.api.getAssignments(1, res[1].meta.paging.total_count)
    ]))
    .then(res => {
      this.parceEbs(res[0]);
      this.assignmentFactory.sync(res[1]);
    })
    .then(() => this.$scope.$apply());
  }

  private loadLocalEbs(): Promise<any> {
    return Promise.resolve(this.getOwn())
    .then(data => { if (!!data && data.length) this.filterActiveEbs(data); });
  }

  private getOwn(): Promise<any> {
    return this.dbFactory.then(ds => ds.db)
    .then((db) => db.valuesByIndex(this.COLECTION_NAME, 'user_id', ydn.db.KeyRange.only(this.authService.userId), 2000))
    .then(list => list.filter(ebs => ebs.user_id  && ebs.user_id === this.authService.authorizedUser.id ||
                                     ebs.user_ids && ebs.user_ids.find(id => id === this.authService.authorizedUser.id) ||
                                     ebs.job      && ebs.job._user_ids && ebs.job._user_ids instanceof Set && ebs.job._user_ids.has(this.authService.authorizedUser.id)))
    .catch(err => {
      if (err instanceof this.errFactory) err.notify();
      else console.error(err);
    });
  }

  private parceEbs(data): Promise<any> {
    return Promise.resolve(data)
    .then(res => {
      this.totalLoaded = res.ebs_data.length;
      this.totalCount  = res.meta.paging.total_count;

      res.ebs_data.forEach(ebs => { ebs.user_id = this.authService.authorizedUser.id; });
      return this.saveEbsList(res.ebs_data).then(() => res.ebs_data);
    })
    .then(data => Promise.all([
      Promise.resolve(data),
      this.getOwn()
    ]))
    .then(data => {
      let validEbs = data[0];
      let localEbs = data[1].filter(db => !data[0].find(api => api.id === db.id));
      if (this.totalLoaded > this.totalCount) localEbs = localEbs.map(ebs => {
        ebs.archived = true;
        return ebs;
      });

      this.filterActiveEbs([...validEbs, ...localEbs]);
      this.$scope.$apply();
      return this.saveEbsList(this.ebsList);
    })
    .then(() => this.loading = false)
    .catch(err => {
      if (err instanceof this.errFactory) err.notify();
      else console.error(err);
    });
  }

  private filterActiveEbs(res): void {
    let active = {};
    this.archivedEBS = [];
    let fourWeeks = 4 * 7 * 24 * 60 * 60 * 1000;
    let data = JSON.parse(JSON.stringify(res));
    data.forEach(e => {
      if ((+Weeks.getEndOfWeek(e.assignment_ends_at) + fourWeeks) > new Date().getTime() && !e.archived) {
        if (active[e.assignment_id]) {
          if (e.version > active[e.assignment_id].version) {
            this.archivedEBS.push(active[e.assignment_id]);
            active[e.assignment_id] = e;
          } else this.archivedEBS.push(e);
        } else active[e.assignment_id] = e;
      } else this.archivedEBS.push(e);
    });
    this.archivedEBS.forEach(e => { e.archived = true; })
    this.activeEBS = Object.values(active);
    this.notConfirmedActiveEBS = this.activeEBS.filter(e => !e.confirmed_at).length;
    this.ebsList = [...this.activeEBS, ...this.archivedEBS];
    if (this.isOpened) this.open();

    this.mappedActiveEBS   = this.mapEBS(this.activeEBS);
    this.mappedArchivedEBS = this.mapEBS(this.archivedEBS);
  }

  private saveEbsList(data): Promise<any> {
    return this.dbFactory.then(ds => ds.db)
    .then((db) => db.put(this.COLECTION_NAME, data));
  }

  openClose(): void {
    if (!this.isOpened && this.ebsList.length) this.open();
    else this.close();
  }

  private open(): void {
    let folder   = document.getElementById('ebs-list-folder');
    let height = [...<any>folder.children].filter(el => el.localName === 'nested-list-item').reduce((sum, val) => sum = sum + +val?.children[0]?.offsetHeight, 0);
    if (this.totalCount > this.totalLoaded) height += 52;
    folder.style.height = height + 'px';

    this.isOpened = true;
    if (this.isOpenedArchive) this.openArchive();
    if (deviceIsIOS) this.fixScrollOnIOS();
  }

  private close(): void {
    let folder = document.getElementById('ebs-list-folder');
    folder.style.height = '0px';
    this.isOpened = false;
    if (deviceIsIOS) this.fixScrollOnIOS();
  }

  openCloseArchive(): void {
    if (!this.isOpenedArchive) this.openArchive();
    else this.closeArchive();
  }
  
  private openArchive(): void {
    let folder  = document.getElementById('ebs-list-folder');
    let archive = document.getElementById('ebs-list-archive-folder');
    let height = [...<any>archive.children].filter(el => el.localName === 'nested-list-item').reduce((sum, val) => sum = sum + +val?.children[0]?.offsetHeight, 0);
    archive.style.height = height + 'px';
    folder.style.height  = (parseInt(folder.style.height) + parseInt(archive.style.height)) + 'px';
    this.isOpenedArchive = true;
    if (deviceIsIOS) this.fixScrollOnIOS();
  }

  private closeArchive(): void {
    let folder  = document.getElementById('ebs-list-folder');
    let archive = document.getElementById('ebs-list-archive-folder');
    folder.style.height  = (parseInt(folder.style.height) - parseInt(archive.style.height)) + 'px';
    archive.style.height = '0px';
    this.isOpenedArchive = false;
    if (deviceIsIOS) this.fixScrollOnIOS();
  }

  private fixScrollOnIOS(): void {
    if (!deviceIsIOS) return;
    var scrollingElements = document.querySelectorAll('.scrollable-content'),
    i = 0;

    clearTimeout(this.timeout);

    for( ; i < scrollingElements.length; i++) {
      scrollingElements[i].setAttribute('style',
          '-webkit-overflow-scrolling:auto;');
    }

    this.timeout = this.$timeout(function() {
      for( i = 0; i < scrollingElements.length; i++) {
        scrollingElements[i].setAttribute('style',
            '-webkit-overflow-scrolling:touch;');
      }
    }, 10);
  }

  private mapEBS(ebsList): any {
    return ebsList.map(ebs => ({
      id:       ebs.id,
      title:    ebs.title,
      subtitle: `version ${ebs.version}`,
      url:     `/ebs/${ebs.assignment_id}/${ebs.version}`,
      warning:  !ebs.confirmed_at && !ebs.archived_at ? 'pleaseConfirmEBS' : null,
    }));
  }

  $onDestroy () {
    this.reloadEbsList && this.reloadEbsList();
  }
}

window.app.component('ebsList', {
  template: require('scripts/components/ebs/ebs-list/ebs-list.html'),
  controller: ['$scope', '$timeout', '$rootScope', 'API', 'AuthService', 'AppFactory', 'AssignmentFactory', 'DBFactory', 'ErrFactory', 'LoaderFactory', EBSList]
});
