class EBSItem {
  $routeParams:      any;
  $location:         any;
  $rootScope:        any;
  $scope:            any;
  $window:           any;
  $timeout:          any;

  api:               any;
  appFactory:        any;
  authService:       any;
  dbFactory:         any;
  errFactory:        any;
  loaderFactory:     any;
  assignmentFactory: any;
  notification:      any;

  assignment_id: number;
  version:       number | string;

  swipeStartX:   number;
  swipeStartY:   number;
  swipeEndX:     number;
  swipeEndY:     number;

  parcedHighlightValues: any[] = [];

  COLECTION_NAME:      string = 'ebs';
  JOBS_COLECTION_NAME: string = 'time_tracking_jobs';
  constructor($routeParams, $location, $rootScope, $scope, $window, $timeout, API, AppFactory, AuthService, DBFactory, ErrFactory, LoaderFactory, AssignmentFactory, Notification) {
    Object.defineProperties(this, {
      $routeParams:      { value: $routeParams      },
      $location:         { value: $location         },
      $rootScope:        { value: $rootScope        },
      $scope:            { value: $scope            },
      $window:           { value: $window           },
      $timeout:          { value: $timeout          },
      api:               { value: API               },
      appFactory:        { value: AppFactory        },
      authService:       { value: AuthService       },
      dbFactory:         { value: DBFactory         },
      errFactory:        { value: ErrFactory        },
      loaderFactory:     { value: LoaderFactory     },
      assignmentFactory: { value: AssignmentFactory },
      notification:      { value: Notification      },
    });

    $rootScope.needEbsConfirm = false;
    this.assignment_id = $routeParams.assignment_id;
    this.version       = $routeParams.version;
    this.loadEbs();
    // let el = document.getElementById('ebs-item');
    // el.addEventListener( 'touchstart', (e) => { this.captureSwipeStart(e); }, false);
    // el.addEventListener( 'touchmove', (e) => { this.captureSwipeMove(e); }, false );
    // el.addEventListener( 'touchend', (e) => { this.handleSwipeEnd(); }, false );

    $scope.$on('$routeChangeStart', function($event, next, current) {
      if ($rootScope.needEbsConfirm &&
          $event.currentScope.ebs && !$event.currentScope.ebs.confirmed_at && !$event.currentScope.ebs.archived &&
          next && !next.$$route.originalPath.includes('/sign-in')) {
        $event.preventDefault();
        Notification.confirm_p({
          title: 'note',
          desc: 'pleaseConfirmEBSBeforeRedirect',
          buttons: ['back', 'ok']
        })
        .then(res => {
          if (res !== 2) {
            $rootScope.needEbsConfirm = false;
            $location.path(next.$$route.originalPath);
          }
        })
        .catch((e) => {
          if (e instanceof ErrFactory) return e.notify();
          else console.error(e);
        });
      } else $location.path(next.$$route.originalPath);
    });
  }

  captureSwipeStart(e): void {
    this.swipeStartX = typeof e.offsetX !== 'undefined' ? e.offsetX : e.touches[0].pageX;
    this.swipeStartY = typeof e.offsetY !== 'undefined' ? e.offsetX : e.touches[0].pageY;
  }

  captureSwipeMove(e): void {
    this.swipeEndX = typeof e.offsetX !== 'undefined' ? e.offsetX : e.touches[0].pageX;
    this.swipeEndY = typeof e.offsetX !== 'undefined' ? e.offsetY : e.touches[0].pageY;
  }

  handleSwipeEnd(): void {
    if (Math.abs(this.swipeStartY - this.swipeEndY) < 100) {
      if (this.swipeStartX - 80 > this.swipeEndX) this.swipeLeft();
      if (this.swipeStartX + 80 < this.swipeEndX) this.swipeRight();
    }
  }

  loadEbs() {
    this.loaderFactory.show();
    Promise.resolve()
    .then(() => {
      if (parseInt(this.version as string)) return this.getOwn().then(data => this.formatApi(data));
      else return this.loadLatest();
    })
    .then(ebs => {
      this.$scope.ebs = ebs;
      this.appFactory.setTitle(this.$scope.ebs.title);
      if (!this.$scope.ebs.confirmed_at && !this.$scope.ebs.archived) this.$rootScope.needEbsConfirm = true;
      this.parceHighlightValues(ebs);
      this.$scope.$apply();
    })
    .then(() => this.authService.loadUser())
    .then(user => {
      this.$scope.user = user;
    })
    .catch(err => {
      if (err instanceof this.errFactory) err.notify();
      else console.error(err);
    })
    .then(() => this.loaderFactory.hide())
    .then(() => this.highlightChanges());
  }

  getOwn(): Promise<any> {
    return this.dbFactory.then(ds => ds.db)
    .then((db) => db.valuesByIndex(this.COLECTION_NAME, 'assignment_id', ydn.db.KeyRange.only(+this.assignment_id)))
    .then(list => list.find(el => this.version === 'max' ? +el.version === +Math.max(...list.map(e => e.version)) : +el.version === +this.version))
    .catch(err => {
      if (err instanceof this.errFactory) err.notify();
      else console.error(err);
    });
  }

  getAssignment(ebs): Promise<any> {
    return Promise.resolve(this.api.getAssignments())
    .then(data => data.assignments)
    .then(data => data.map(j => new this.assignmentFactory(Object.assign({}, j, {
      user_id: this.authService.userId
    }))))
    .then((assignments) => Promise.all(assignments.map(j => j.save()))
      .then(() => assignments)
    )
    .then((assignments) => {
      let assignment = assignments.find(j => +j.id === +this.assignment_id);
      if (assignment) ebs.assignment = assignment;
      return ebs;
    })
    .catch(err => {
      if (err instanceof this.errFactory) err.notify();
      else console.error(err);
    });
  }

  loadLatest(): Promise<any> {
    return Promise.resolve(this.api.getLatestEBS(this.assignment_id))
    .then(ebs => this.formatApi(ebs))
    .then(ebs => this.getAssignment(ebs))
    .then(ebs_data => this.save(ebs_data))
    .then(ebs_data => this.saveJob(ebs_data))
    .catch(err => {
      if (err instanceof this.errFactory) err.notify();
      else console.error(err);
    });
  }

  save(ebs_data): Promise<any> {
    return this.dbFactory.then(ds => ds.db)
    .then((db) => db.put(this.COLECTION_NAME, ebs_data))
    .then(() => ebs_data)
    .catch(err => {
      if (err instanceof this.errFactory) err.notify();
      else console.error(err);
    });
  }

  saveJob(ebs): Promise<any> {
    return this.assignmentFactory.getOwn()
    .then((assignments) => assignments.find(j => j.id === +this.assignment_id))
    .then((assignment) => {
      if (assignment) { 
        assignment.confirmed = !!ebs.confirmed_at;
        return this.dbFactory.then(ds => ds.db)
        .then((db) => db.put(this.JOBS_COLECTION_NAME, assignment))
      } else return Promise.reject();
    })
    .catch(err => {
      if (err instanceof this.errFactory) err.notify();
      else console.error(err);
    })
    .then(() => ebs);
  }

  confirm(): Promise<void> {
    this.loaderFactory.show();
    return Promise.resolve(this.appFactory.checkNewVersion())
    .then(() => this.api.confirmEBS(this.$scope.ebs.id))
    .then(() => {
      this.$scope.ebs.confirmed_at = new Date().toISOString();
      this.$rootScope.needEbsConfirm = false;
    })
    .then(() => this.save(this.$scope.ebs))
    .then(() => this.saveJob(this.$scope.ebs))
    .then(() => {
      if (this.version && isNaN(this.version as number)) {
        this.$rootScope.recentConfirmedJob = this.$scope.ebs.assignment_id;
        this.appFactory.goBack();
      }
      else this.$location.path('/');
    })
    .catch(err => {
      if (err && err.store_url) {
        this.$location.path('/blocker');
        this.$scope.$apply();
      }
      if (err instanceof this.errFactory) err.notify();
      else console.error(err);
    })
    .then(() => this.loaderFactory.hide());
  }

  formatApi(ebs) {
    ebs.aufarbs = ebs.aufarbs ? Array.isArray(ebs.aufarbs) ? ebs.aufarbs : ebs.aufarbs.split(';') : [];
    ebs.auffahrbetrag = this.formatPayValue(ebs.auffahrbetrag);
    ebs.aufueentfernung = this.formatPayValue(ebs.aufueentfernung);
    ebs.aufvmabetrag = this.formatPayValue(ebs.aufvmabetrag);
    ebs.aufuebernbetrag = this.formatPayValue(ebs.aufuebernbetrag);
    ebs.pel705preis = this.formatPayValue(ebs.pel705preis);
    ebs.txt_sonstigeZulage = this.formatPayValue(ebs.txt_sonstigeZulage);
    ebs.aufarbzeitwoche = isNaN(ebs.aufarbzeitwoche) ? ebs.aufarbzeitwoche : ebs.aufarbzeitwoche.toFixed(1).replace('.', ',');
    return ebs;
  }

  parceHighlightValues(ebs) {
    for (const key in ebs.previous_data_was) {
      this.parcedHighlightValues.push(this.mapHighlightValues(key));
    }
  }

  mapHighlightValues(fieldName: string) {
    switch (fieldName) {
      case 'aufueberlbeginn':
        return 'Einsatzbeginn:';
      case 'aufmuhrzeit':
        return 'Meldeuhrzeit:';
      case 'aufadresse1':
        return 'Meldeadresse:';
      case 'aufbetrstaette':
        return 'Betriebsstätte:';
      case 'aufansprech1':
        return 'Meldeansprechpartner:';
      case 'anspr_telefon':
        return 'Meldeansprechpartner Telefon:';
      case 'auftelefon1':
        return 'Meldetelefon:';
      case 'aufberuf':
        return 'Einsatzberuf:';
      case 'aufpostaet1':
        return 'Einsatztätigkeits beschreibung:';
      case 'aufarbzeitwoche':
        return 'Arbeitszeit pro Woche:';
      case 'auftextmarken4':
        return 'Sonstige Bemerkungen:';

      case 'aufgefahrarbeit':
        return 'Gefährliche Arbeit';
      case 'aufuntersuchidx':
        return 'Untersuchungen';

      case 'aufueentfernung':
        return 'KM Entfernung:';
      case 'auffahrbetrag':
        return 'Fahrbetrag:';
      case 'aufvmabetrag':
        return 'VMA:';
      case 'aufuebernbetrag':
        return 'Übernachtungsbetrag:';

      case 'pel705preis':
        return 'Entsendezulage:';
      case 'txt_sonstigeZulage':
        return 'Sonstige Zulagen:';

      case 'auftarifgruppe':
        return 'Entgeltgruppe:';
      case 'auftarifmagruppe':
        return 'Entgelttarif:';
      case 'aufdeckelbetragakt':
        return 'Deckelungsbetrag:';

      case 'auftarifbranche':
        return 'Branchenzuschlags tarifvertrag:';
      case 'auftarifvertrag':
        return 'Tarifvertrag:';

      case 'kundname1':
      case 'kundstrasse':
      case 'kundplz':
      case 'kundort':
        return 'CustomerInfo';

      default:
        return null;
    }
  }

  highlightChanges() {
    var spans: any = document.getElementsByTagName("span");
    this.parcedHighlightValues.forEach(el => {
      for (var i = 0; i < spans.length; i++) {
        if (spans[i].innerText == el) {
          spans[i].nextElementSibling.classList.add('color-orange', 'bold');
          break;
        }
      }
    });
  }

  formatPayValue(val) {
    return isNaN(val) ? val : val.toFixed(2).replace('.', ',');
  }

  openMap(value) {
    if (window.cordova) {
      if (deviceIsIOS) this.$window.open(`maps://?q=${value}`, '_system');
      else this.$window.open(`geo:0,0?q=${value}`, '_system');
    }
    else this.$window.open(`https://www.google.com/maps?q=${value}` , '_system');
  }

  call(phone) {
    let tel;
    let num = phone.replace(/ /g, '');
    if (num) {
      if (num.charAt(0) === '0') tel = `+49${num.substring(1)}`;
      else if (num.substring(0,2) === '49' && num.length === 12) tel = `+${num}`;
      else if (num.substring(0,3) === '+49') tel = `${num}`;
      else tel = `+49${num}`;
    } else tel = '+49 699 0501 4320'; // Tempton Digital (Support)

    let link = `tel:${tel}`;
    return this.$window.open(link, '_system');
  }

  back() {
    return this.$location.path('/');
  }

  swipeLeft() {
    if (this.firstPage()) this.swipe('-100%');
  }

  swipeRight() {
    if (!this.firstPage()) this.swipe('');
  }

  swipe(left) {
    let el = document.getElementById('ebs-item');
    el.style.left = left;
    this.$scope.$apply();
  }

  firstPage() {
    let el = document.getElementById('ebs-item');
    return !parseInt(el.style.left);
  }
}

window.app.component('ebsItem', {
  template: require('scripts/components/ebs/ebs-item.html'),
  controller: ['$routeParams', '$location', '$rootScope', '$scope', '$window', '$timeout', 'API', 'AppFactory', 'AuthService', 'DBFactory', 'ErrFactory', 'LoaderFactory', 'AssignmentFactory', 'Notification', EBSItem]
});
