import { CameraPreviewStartCameraOptions } from "cordova-plugin-camera-preview";

class CameraUX {
  $scope:                    any;
  $filter:                   any;
  $location:                 any;
  loaderFactory:             any;
  fileSystemService:         any;
  requestPermissionsService: any;

  height:      number;
  width:       number;

  horizont:    number;
  rotate:      number;

  image:       string;
  orientation: string;
  hintTimer:   boolean = true;
  onConfirm:   Function;
  close:       Function;

  accelerometerWatch: WatchHandle;
  constructor($scope: any, $location: any, $filter: any, FileSystemService: any, LoaderFactory: any, RequestPermissionsService: any) {
    Object.defineProperties(this, {
      $scope:                    { value: $scope                    },
      $filter:                   { value: $filter                   },
      $location:                 { value: $location                 },
      loaderFactory:             { value: LoaderFactory             },
      fileSystemService:         { value: FileSystemService         },
      requestPermissionsService: { value: RequestPermissionsService },
    });

    if (window.cordova) {
      document.body.classList.add("camera-preview");
      window.addEventListener("orientationchange", this.watchDeviceRotate.bind(this), true);

      this.requestPermissionsService.camera()
      .then(() => {
        CameraPreview.setFocusMode(CameraPreview.FOCUS_MODE.AUTO);
        CameraPreview.onBackButton(() => {
          if (this.image) this.resetCamera();
          else this.close();
        });
        this.hintTimer = true;
        setTimeout(() => this.hintTimer = false, 3000);
        this.startPreview();
      })
      .catch(err => {
        this.$location.to('/');
      });
    } else this.$location.to('/');
  }

  private watchDeviceRotate(): void {
    if (this.accelerometerWatch) {
      this.stopPreview();
      this.startPreview();
    }
  }

  private startPreview(): void {
    this.startCamera();
    this.watchAcceleration();
  }

  private startCamera(): void {
    let options: CameraPreviewStartCameraOptions = {
      camera:      CameraPreview.CAMERA_DIRECTION.BACK,
      toBack:      true,
      tapPhoto:    false,
      tapFocus:    true,
      previewDrag: false,
      storeToFile: true,
      disableExifHeaderStripping: true
    };

    if (screen.orientation.type.includes('portrait')) {
      this.width  =  window.screen.width - 50;
      this.height = (window.screen.width - 50) * 1.41;
    } else {
      if (deviceIsIOS) {
        options.width  =  window.screen.height;
        options.height =  window.screen.width;
        this.height    =  window.screen.width - 50;
        this.width     = (window.screen.width - 50) * 1.41; 
      } else {
        this.height    =  window.screen.height - 50;
        this.width     = (window.screen.height - 50) * 1.41; 
      }
    }

    CameraPreview.startCamera(options);
  }

  private stopPreview(): void {
    CameraPreview.stopCamera();
    navigator.accelerometer.clearWatch(this.accelerometerWatch);
    this.accelerometerWatch = null;
    this.horizont           = null;
    this.rotate             = null;
  }

  private watchAcceleration(): void {
    // let options = { frequency: 25 };
    let options = { frequency: 200 };
    this.accelerometerWatch = navigator.accelerometer.watchAcceleration(
      (acceleration) => {
        // let rotate;
        // if (this.orientationPortrait())  rotate = Math.round(acceleration.x * 9);
        // if (this.orientationLandscape()) rotate = Math.round(acceleration.y * 9);
        // this.rotate   = Math.min(90,  Math.max(-90, rotate));

        // let horizont = Math.round(acceleration.z * 10);
        // if (horizont >= -50 && horizont <= 50) horizont = horizont + 50;
        // else horizont = 100 - (horizont-50);
        // this.horizont = Math.min(100, Math.max(-50, horizont));

        this.$scope.$apply();
      },
      (err) => console.log(err),
      options
    );
  }

  takePicture(): void {
    let options = { quality: 80 };
    this.loaderFactory.show();
    this.fileSystemService.cleanCacheFolder()
    .then(() => {
      CameraPreview.takeSnapshot(options, base64 => {
        this.stopPreview();
        let blob = this.fileSystemService.dataURItoBlob(`data:image/jpeg;base64,${ base64?.length ? base64[0] : base64 }`);
        return this.fileSystemService.writeFileToSystem(blob, `temp_photo_tn_${this.$filter('date')(new Date(),'yyyy_MM_dd')}.jpeg`, 'cache')
        .then(fileEntry => {
          if (deviceIsAndroid) this.image = fileEntry.toInternalURL();
          if (deviceIsIOS)     this.image = fileEntry.nativeURL;
          this.loaderFactory.hide();
        });
      });
    });
  }

  resetCamera(): void {
    this.image = null;
    this.startPreview();
  }

  useImage(): void {
    this.loaderFactory.show();

    var canvas = document.createElement("canvas");
    var ctx = canvas.getContext("2d");
    document.body.appendChild(canvas);

    var image = new Image();
    image.setAttribute('crossOrigin', 'anonymous');
    image.src = this.image;
    image.onload = () => {
      if (this.orientationLandscape()) {
        canvas.width  = image.height;
        canvas.height = image.width;
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.translate(image.height/2, image.width/2);
        ctx.rotate(90*Math.PI/180);
        ctx.drawImage(image, -image.width/2, -image.height/2);
      } else {
        canvas.width  = image.width;
        canvas.height = image.height;
        ctx.drawImage(image, 0, 0);
      }

      this.onConfirm({ image: canvas.toDataURL('image/jpeg') });
      this.loaderFactory.hide();
    };
    document.body.removeChild(canvas);
  }

  orientationPortrait(): boolean {
    return screen.orientation.type.includes('portrait');
  }

  orientationLandscape(): boolean {
    return screen.orientation.type.includes('landscape');
  }

  getRotateStyle() {
    let transform = 'translate(-50%, -50%)';
    if (this.rotate) transform += ` rotate(${this.orientationLandscape() ? this.rotate * -1 : this.rotate}deg)`;
    return {
      top:  '50%',
      left: '50%',
      transform
    };
  }

  getRotateAngle() {
    return Math.abs(this.rotate);
  }

  getAngleTextStyle() {
    return {
      top:       '10px',
      left:      '50%',
      transform: `translate(-50%, -50%)`
    };
  }

  getHorizontStyle() {
    return { 
      left:      '50%',
      top:        this.horizont + '%',
      transform: `translate(-50%, -${this.horizont}%)`
    };
  }

  $onDestroy(): void {
    this.stopPreview();
    this.fileSystemService.cleanCacheFolder();
    screen.orientation.unlock();
    document.body.classList.remove("camera-preview");
    window.removeEventListener("orientationchange", this.watchDeviceRotate, true);
  }

}

window.app.component('cameraUx', {
  template: require('scripts/components/camera-ux/camera-ux.html'),
  controller: ['$scope', '$location', '$filter', 'FileSystemService', 'LoaderFactory', 'RequestPermissionsService', CameraUX],
  bindings: {
    onConfirm: '&',
    close:     '&'
  }
});
